
import { signIn } from 'next-auth/client';
import { useEffect } from 'react';
import withAppInsightsPage from '../HOC/withAppInsights';
import withContainer from '../HOC/withContainer';

export { getServerSideEnvVars as getServerSideProps } from "../lib/server-props";

function LoginPage(props) {
  const { process } = props;
  console.log('process: ', process);

  function loginHandler() {  
    const domain = process.env.NEXT_PUBLIC_DOMAIN;
    const callbackUrl = `${domain}/authenticated`
    localStorage.removeItem('username');
    signIn(
      'salesforce',
      {
        redirect: true,
        callbackUrl: callbackUrl
      }
    )
  }

  useEffect(() => {
    setTimeout(() => {
      loginHandler();
    }, 0);
  });

  return (
    <div>
    </div>
  )
}
export default withContainer(withAppInsightsPage(LoginPage));