/* eslint-disable react/display-name */

import AppBar from '../components/OneControl/AppBar';
import Styles from '../lib/Styles';
import classes from './withContainer.module.scss';

const withContainer = (WrappedComponent: any) => {
    const cx = new Styles(classes).cx;

    return (props: any) => {

        return (
            <>
                <AppBar />
                <div className={cx('container')}>
                    <div className={cx('overlay')} />
                    <div className={cx('contents')} />

                    <div className={cx('body')}>
                        <div className={cx('region')}>
                            <div className={cx('centered')}>
                                <div className={cx('letter-spacing')}>
                                    <WrappedComponent {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };
};

export default withContainer;
