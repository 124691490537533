/* eslint-disable react/display-name */

import { useContext, useEffect } from 'react';

import recordApplicationInsights, { IApplicationInsights } from '../lib/api-utility';
import AppContext from '../store/app-context';

// This HOC must be the innermost wrapper in a multi-layer HOC around a page.
const withAppInsightsPage = (WrappedComponent: any) => {
    const page = WrappedComponent.name;
    return (props: any) => {
        const { developerMode, setPage, contactId } = useContext(AppContext);
        const kcode = localStorage.getItem('kcode');

        useEffect(() => {
            setPage(page);
            localStorage.setItem('page', page);
            const data: IApplicationInsights = {
                kcode: kcode ? kcode : 'undefined',
                contactId: contactId ? contactId : 'undefined',
                index: "undefined",
                page: page,
                component: "undefined",
                developerMode: developerMode ? 'true' : 'false',
                href: 'undefined',
                name: `${page}_Rendered`
            };
            recordApplicationInsights(data);
        });

        return (
            <>
                <WrappedComponent {...props} page={page} />
            </>
        )
    };
};

export default withAppInsightsPage;
